<!--注册页面-->
<template>
  <div class="content" :class="model == true ? '' : 'row-between'">
<!--    <el-menu-->
<!--        v-if="model == false"-->
<!--        :default-active="activePc"-->
<!--        class="el-menu-vertical-demo"-->
<!--    >-->
<!--      <el-menu-item index="0" @click="$router.push('/chart')">-->
<!--        <el-icon><icon-menu /></el-icon>-->
<!--        <span>首页</span>-->
<!--      </el-menu-item>-->
<!--      <el-menu-item index="1"  @click="$router.push('/home')">-->
<!--        <el-icon><document /></el-icon>-->
<!--        <span>我的</span>-->
<!--      </el-menu-item>-->
<!--    </el-menu>-->

    <keep-alive include="chart">
      <RouterView class="homeContent"></RouterView>
    </keep-alive>
    <van-tabbar v-model="active" active-color="#16a37f" inactive-color="#000">
      <van-tabbar-item icon="chat-o" to="/chart">问答</van-tabbar-item>
      <van-tabbar-item icon="like-o" dot to="/examples">应用</van-tabbar-item>
      <!--<van-tabbar-item icon="question-o" to="/help">帮助</van-tabbar-item>-->
      <van-tabbar-item icon="question-o" @click="toMission">任务</van-tabbar-item>
      <van-tabbar-item icon="link-o" url="https://www.wph1.cn/">高级版</van-tabbar-item>
      <van-tabbar-item icon="user-circle-o" @click="toHome">我的</van-tabbar-item>
    </van-tabbar>

    <van-popup v-model:show="mobilePopupShow" :style="{ padding: '30px' }">
      <div class="content">
        <div class="main">
          <div class="mainContainer">
            <div class="loginName">账号登录</div>
            <div class="main_form">
              <van-form @submit="onSubmit">
                <van-cell-group inset>
                  <van-field style="border: 1px solid #D8D8D8;margin-top:2vh"
                      v-model="form.username"
                      name="请输入手机号"
                      placeholder="请输入手机号"
                      :rules="[{ required: true, message: '请输入手机号' },
                { validator: validatorTel, message: '手机号输入不合法' }]"
                  />
                  <van-field style="border: 1px solid #D8D8D8;margin:2vh 0"
                      v-model="form.password"
                      :type="isShow ? 'text' : 'password' "
                      name="请输入密码"
                      placeholder="请输入密码"
                      :right-icon="isShow ? 'eye-o' : 'closed-eye'"
                      @click-right-icon="changePasswordIcon"
                      :rules="[{ required: true, message: '请输入密码' }]"
                  />
                </van-cell-group>
                <div>
                  <van-button block type="primary" native-type="submit" class="formButton" color="#16a37f" style="margin-bottom:2vh;">
                    登录
                  </van-button>
                </div>
              </van-form>
              <div class="row-between toPasswordRegister">
                <div class="pointerCursor" @click="$router.push('/forgetPassword')">
                  忘记密码？
                </div>
                <div class="pointerCursor" @click="$router.push('/register')">
                  新用户账号注册
                </div>
              </div>
            </div>
          </div>
          <div style="margin-top:20px;"><explanatory></explanatory></div>
        </div>
      </div>
    </van-popup>
    <van-popup v-model:show="pcPopupShow" :style="{ padding: '64px' }" closeable>
      <div style="text-align:center;">微信扫码登录助手AI</div>
      <div><img :src="qrCode" alt="登录二维码" @click.stop></div>
      <div style="text-align:center;">使用微信扫码-关注公众号登录</div>
      <explanatory></explanatory>
    </van-popup>
  </div>
</template>

<script>
import { ref, onMounted, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { reactive, toRefs } from 'vue'
import explanatory from '@/components/explanatory'
import user from '@/store/modules/user'
import { getQrcode, checkWechatScan, notifyWechatUser, checkWechatUserAuth } from '@/api/login'
import Cookies from 'js-cookie'
import { _isMicroMessager } from '@/utils/common'

export default {
  name: 'register',
  setup() {
    const store = useStore()
    const active = ref(1)
    const route = useRoute()
    const router = useRouter()
    const model = computed(() => store.getters.isModel)

    const mobilePopupShow = ref(false);
    const pcPopupShow = ref(false);
    const noticeShow = ref(false);
    let isLogin = store.getters.isLogin == 0 ? false : true;
    const state2 = reactive({
      qrCode: '',
      scanStatus: 0,
      to: ''
    })

    //todo: 需要测试此处的name是否正确，或者应该使用其他比如 code
    const code = decodeURIComponent((new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(location.href) || [, ""])[1].replace(/\+/g, "%20")) || null

    if( !isLogin && code ){
      checkWechatUserAuth().then(res => {
        console.log("output the res for checkWechatUserAuth")
        console.log(res.data)
        if (res.data.scanStatus == 1) {
          if (res.data.adminToken != undefined) {
            Cookies.set("Admin-Token", adminToken, { expires: 7 })
            isLogin = true
            active.value = 0
          }
        }
      })
    }

    const toMission = () => {
      state2.to = 'mission'
      showPopup()
    }

    const toHome = () => {
      state2.to = 'home'
      showPopup()
    }
    const checkQrResult = () => {
      checkWechatScan()
          .then(res => {
            state2.scanStatus = res.data.scanStatus
            if(state2.scanStatus == 1){
              pcPopupShow.value = false
              const adminToken = res.data.adminToken
              if(adminToken != undefined){
                Cookies.set("Admin-Token", adminToken, { expires: 7 })
              }
              isLogin = true
              if (state2.to == 'mission'){
                active.value = 2;
                router.push('/mission')
              } else {
                active.value = 0;
                location.reload()
              }
            }else if(state2.scanStatus == 0 && pcPopupShow.value == true){
              setTimeout(() => {
                checkQrResult()
              }, 1000)
            }
          })
          .catch((msg) => {})
    }
    const showPopup = () => {
      if(isLogin){
        if(state2.to == 'mission'){
          router.push('/mission')
        }else {
          router.push('/home')
        }
      }else{
        if (_isMicroMessager()) {
          // 此处记得修改为通过读取配置文件获取回调地址
          const WECHAT_CALL_BACK_DOMAIN = 'https://www.wph3.cn/'
          const APPID = 'wx3583e957567a826a'
          //const WECHAT_CALL_BACK_DOMAIN = 'http://www.wph1.cn/'
          //const APPID = 'wx7d6958ebf1065f67'
          const local = encodeURIComponent(WECHAT_CALL_BACK_DOMAIN + '#/home') //获取当前页面地址作为回调地址
          const myUrl = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + APPID + '&redirect_uri=' + local +'&response_type=code&scope=snsapi_userinfo&state=2324#wechat_redirect'
          console.log("wechat login url " + myUrl)
          window.location.href = myUrl

        } else if (model.value == true) {
          mobilePopupShow.value = true;
        } else {
          getQrcode()
              .then(res => {
                state2.qrCode = res.data.url
                pcPopupShow.value = true;
                if(state2.scanStatus == 0){
                  checkQrResult()
                }
              })
              .catch((msg) => {})
        }
      }
    }
    const isShow = ref(false)
    const state = reactive({
      form: {
        username: '',
        password: ''
      },
      validatorTel: false,
      phoneGui: /^[0-9]{11}$/
    })
    state.validatorTel = (val) => state.phoneGui.test(val)
    const onSubmit = (values) => {
      store.dispatch('Login', state.form).then(() => {
        store.dispatch('GetInfo').then(() => {
          isLogin = store.getters.isLogin == 0 ? false : true;
          if(state2.to == 'mission'){
            router.push('/mission')
            mobilePopupShow.value = false;
            active.value = 2
          } else if (store.getters?.level == 0) {
            router.push('/')
            mobilePopupShow.value = false;
            active.value = 3
          } else {
            router.push('/chart')
            mobilePopupShow.value = false;
            active.value = 0
          }
        })
      }).catch(() => {
      })
    }
    const methods = reactive({
      changePasswordIcon () {
        isShow.value = !isShow.value
      }
    })

    onMounted(() => {
      if (route.name === "chart") {
        active.value = 0;
      } else if (route.name === 'examples') {
        active.value = 1
      } else if (route.name === "mission") {
        active.value = 2;
      } else if (route.name === "home") {
        active.value = 3;
      } else if (route.name === "name") {
        active.value = 4;
      }
    })

    watch(() => router.currentRoute.value, (newValue, oldValue) => {
      if (newValue.name === "chart") {
        active.value = 0;
      } else if (route.name === 'examples') {
        active.value = 1
      } else if (newValue.name === "mission") {
        active.value = 2;
      } else if (newValue.name === "home") {
        active.value = 3;
      } else if (newValue.name === "name") {
        active.value = 4;
      }
    }, { immediate: true })

    return {
      active,
      model,
      mobilePopupShow,
      pcPopupShow,
      noticeShow,
      showPopup,
      isShow,
      onSubmit,
      ...toRefs(methods),
      ...toRefs(state),
      isLogin,
      ...toRefs(state2),
      checkQrResult,

      toMission,
      toHome
    }
  },
  components: {
    explanatory
  }
}
</script>


<style lang="less">

</style>
